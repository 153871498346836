import { Global } from "@emotion/react";
import Link from "next/link";
import { FC, ReactNode } from "react";

import { Button, Container } from "@aviary";
import { Head } from "@components";
import * as reset from "@styles/normalize.styles";

import LogoSvg from "../../assets/svgs/fullscript-integrate-logo.svg";
import { AuthPageFooter } from "../../components/AuthPageContent/AuthPageFooter";

import * as styles from "./ErrorPageWrapper.styles";

interface Props {
  title: string;
  heading: ReactNode;
  text: ReactNode;
  icon: ReactNode;
}

const ErrorPageWrapper: FC<Props> = ({ title, heading, text, icon }) => {
  return (
    <>
      <Global styles={reset.normalize} />
      <Head title={title} />
      <div css={styles.wrapper}>
        <nav css={styles.nav}>
          <Container>
            <Link href="/applications" passHref>
              <Button noStyle>
                <LogoSvg css={styles.logoSvg} />
              </Button>
            </Link>
          </Container>
        </nav>
        <Container css={styles.container}>
          <div css={styles.content}>
            <aside css={styles.icon}>{icon}</aside>
            <section css={styles.text}>
              {heading}
              {text}
              <Button isColor="system" css={styles.homeButton} href="/">
                Go Home
              </Button>
            </section>
          </div>
          <AuthPageFooter />
        </Container>
      </div>
    </>
  );
};

export { ErrorPageWrapper };

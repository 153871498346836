import { css } from "@emotion/react";

import { colors, dimensions, helpers } from "@styles";

export const nav = css`
  padding: 0.75rem 0 0.5rem;
  box-shadow: 0px 1px 2px ${helpers.hexToRgba(colors.grey.darkFamily.normal, 0.16)};
`;

export const logoSvg = css`
  height: 2rem;
`;

export const wrapper = css`
  background-color: ${colors.white};
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5rem;
`;

export const container = css`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const icon = css`
  width: 40%;

  @media (max-width: ${dimensions.tabletMax}) {
    width: 100%;
    display: flex;
  }
`;

export const text = css`
  display: flex;
  flex-direction: column;
  width: 60%;
  gap: 1rem;

  @media (max-width: ${dimensions.tabletMax}) {
    width: 100%;
    text-align: center;
    align-items: center;
    max-width: 40rem;
  }
`;

export const content = css`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  margin-inline: auto;
  row-gap: 2rem;
  margin-top: 2rem;
`;

export const homeButton = css`
  font-weight: normal;

  @media (min-width: ${dimensions.desktop}) {
    align-self: flex-end;
  }
`;
